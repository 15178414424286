/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

ngx-charts-chart {
  .ngx-charts {
    float: left;
    overflow: visible;
  }
}

.tooltip-item {
  display: flex;
}
